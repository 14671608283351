// External
import React, {
	useContext,
	useEffect,
	useState,
	useRef,
	lazy,
	Suspense,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { v4 as uuid } from 'uuid';
import { storyblokInit } from 'gatsby-source-storyblok';
import axios from 'axios';

// Internal
import Password from '../components/Password';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { customerQuery } from '../api/queries/customer';

import { captureAttributionData, useWindowWidth } from '../utils/hooks';
import { customerFetch, isBrowser } from '../utils/helpers';
import { StoreContext } from '../context/store-context';
import storyblokComponents from '../utils/storyblokComponents';
import useCustomer from '../utils/useCustomer';
import { submitErrorToSlack } from '../utils/slackMessage';
import packageInfo from '../../package.json';
import useCheckStorageVersion from '../helpers/useCheckStorageVersion';
import Overlay from '../components/Overlay';
import Cart from '../components/Cart';
import Loader from '../components/Loader';
import ErrorPage from '../components/ErrorPage';
import useLoadKlaviyoScript from '../hooks/useLoadKlaviyoScript';

const ShotModalContainer = lazy(() => import('../components/ShotModal'));
const Modal = lazy(() => import('../components/Modal'));
const Notification = lazy(() => import('../components/Notification'));

const Layout = ({ children, location }) => {
	const [isAuth, setIsAuth] = useState(true);
	const [password, setPassword] = useState('');
	const [news, setNews] = useState(null);
	useLoadKlaviyoScript(isBrowser);
	const { customerData } = useCustomer();
	const version = packageInfo.version;
	const width = useWindowWidth();
	const {
		cart,
		allProducts,
		customerData: customerDataContext,
		setCustomerData,
	} = useContext(StoreContext);

	useCheckStorageVersion(version);
	captureAttributionData();

	storyblokInit({
		components: storyblokComponents,
	});

	const handleSubmitForm = (e) => {
		e.preventDefault();
		setIsAuth(password === 'colour');
	};

	const searchMetafield = (metafieldToSearch, metafields) => {
		const metafieldExists =
			metafields.find((item) => item.key === metafieldToSearch) || null;

		return metafieldExists;
	};
	const setMetafields = async () => {
		try {
			const response = await axios.get('/api/getShopMetafields');
			if (!response.data) {
				throw new Error('API request failed');
			}

			const data = response.data;
			const metafields = data.metafields;
			localStorage.setItem('ShopMetafields', JSON.stringify(metafields));

			const allNews =
				metafields.find(
					(item) => item.key === 'rotating_text_banner_messages'
				).value || null;
			setNews(allNews);

			return metafields;
		} catch (error) {
			const status = error?.response?.status;
			if (status >= 500) {
				submitErrorToSlack('/api/getShopMetafields', error, 'GET');
			}
			console.error(error);
			return error;
		}
	};

	useEffect(() => {
		setMetafields();
	}, []);

	useEffect(() => {
		if (!isBrowser) return;

		(async () => {
			const isLoggedIn = customerData && customerData.customer;
			if (isLoggedIn) {
				if (!customerDataContext) {
					const customerId = JSON.stringify(
						customerQuery(customerData.customer.id)
					);
					const customer = await customerFetch(customerId);
					setCustomerData(customer);
				}

				if (customerDataContext) {
					window?.dataLayer?.push({
						event: 'dl_user_data',
						event_id: uuid(),
						cart_total: cart?.cost?.totalAmount.amount,
						user_properties: {
							visitor_type: 'logged_in',
							customer_id: customerDataContext.id,
							customer_email: customerDataContext.email,
							customer_order_count:
								customerDataContext.orders.edges.length,
							customer_total_spent:
								customerDataContext.amountSpent,
							customer_tags: customerDataContext.tags,
							user_consent: 'yes|no|no_interaction',
						},
					});
				}
			} else {
				window?.dataLayer?.push({
					event: 'dl_user_data',
					event_id: uuid(),
					cart_total: cart?.cost?.totalAmount.amount,
					user_properties: {
						visitor_type: 'guest',
					},
				});
			}
		})();
	}, [location, cart?.lines?.edges?.length]);

	useEffect(() => {
		const launcherElement = document.getElementById('launcher');
		if (launcherElement) {
			if (location.pathname.includes('products/') && width < 990) {
				launcherElement.classList.add('launcher-mobile');
			} else {
				launcherElement.classList.remove('launcher-mobile');
				launcherElement.classList.add('launcher-desktop');
			}
		}
	}, [location, width]);

	if (!isAuth) {
		return (
			<Password
				password={password}
				setPassword={setPassword}
				handleSubmitForm={handleSubmitForm}
			/>
		);
	}
	const footerExcludedPaths = ['/pages/thank-you', '/miracle-steps'];
	const shouldHideFooter = footerExcludedPaths.some((path) =>
		location?.pathname?.includes(path)
	);

	return (
		<ErrorBoundary FallbackComponent={ErrorPage}>
			<noscript>
				<iframe
					src='https://www.googletagmanager.com/ns.html?id=GTM-MX9SFG5'
					height='0'
					width='0'
					style={{ display: 'none', visibility: 'hidden' }}
					title='Google Tag Manager'
				/>
			</noscript>
			<Header
				pageHandle={location?.pathname}
				location={location}
				news={news}
			/>

			<main>{children}</main>
			<Suspense fallback={<Loader />}>
				<ShotModalContainer />
				<Modal />
				<Notification />
			</Suspense>

			<Cart productsList={allProducts} />
			<Overlay />

			{!shouldHideFooter && <Footer location={location} />}
		</ErrorBoundary>
	);
};

export default Layout;

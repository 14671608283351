import React, { useContext, useState } from 'react';

import { useWindowWidth } from '../../../utils/hooks';
import ProductItem from './ProductItem';
import { getMetafield } from '../../../utils/helpers';

import './ProductCollections.scss';
import { StoreContext } from '../../../context/store-context';

const ProductCollections = ({
	product,
	otherCollections,
	sellingPlanGroup,
	cart,
}) => {
	const { allProducts } = useContext(StoreContext);
	const windowWidth = useWindowWidth();

	const collectionsPair = getMetafield(product, 'collections_pair');

	const [activeButton, setActiveButton] = useState(0);

	const collections = collectionsPair && collectionsPair.split('|');

	const collectionsFiltered = otherCollections?.filter((item) =>
		collections.includes(item.handle)
	);


	const products =
		collectionsFiltered && collectionsFiltered[activeButton] && allProducts
			? allProducts
					.filter((product) =>
						product.collections.some(
							(collection) =>
								collection.handle ===
								collectionsFiltered[activeButton].handle
						)
					)
					.slice(0, windowWidth > 800 ? 5 : 4)
			: [];


	return (
		<div className='container pdp-collections'>
			<div className='pdp-collections__title'>
				<h2>
					Browse more Josh Wood Colour products{' '}
					{windowWidth > 750 && <br />}your hair will fall in love
					with
				</h2>
			</div>
			<div className='pdp-collections__content'>
				<div className='pdp-collections__content__buttons'>
					{collectionsFiltered?.map((collection, index) => (
						<button
							key={collection.handle}
							className={`pdp-collections__content__buttons__button ${
								activeButton === index &&
								'pdp-collections__content__buttons__button--active'
							}`}
							onClick={() => setActiveButton(index)}
						>
							{collection.title}
						</button>
					))}
				</div>

				<div className='pdp-collections__content__products'>
					{products?.map((product) => (
						<ProductItem
							key={product.handle}
							product={product}
							sellingPlanGroup={sellingPlanGroup}
							cart={cart}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProductCollections;

/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import LocalImage from '../../LocalImage';
import { useWindowWidth } from '../../../utils/hooks';

import './ShadeShotItem.scss';

const CartUpsell = ({
	item,
	upsellShot,
	upsellVariant,
	upsellBaseColour,
	upsellShotID,
	cart,
	openModal,
	options,
	miracleSystem,
	selectedValue,
	setSelectedValue,
	sellingPlanId,
	replaceCartItem,
	closeCart
}) => {
	const productHasShadeShot =
		item.node.merchandise.product.title.includes('Shade Shot');

	const [isDesktop, setDesktop] = useState(false),
		width = useWindowWidth(),
		shotCustomAttrs = [
			{
				key: 'isUpsellCart',
				value: 'true',
			},
			{
				key: 'baseColourId',
				value: upsellBaseColour
					? upsellBaseColour.storefrontId
					: 'null',
			},
			{
				key: 'variantId',
				value: upsellVariant ? upsellVariant.storefrontId : 'null',
			},
			{
				key: 'shotId',
				value: 'null',
			},
		],
		handleChangeTone = () => {
			closeCart();
			openModal();
		},
		handleHelp = () => {},
		handleRemoveShot = () => {
			replaceCartItem(
				cart.id,
				item,
				item.node.merchandise.id,
				item.node.id,
				upsellBaseColour.storefrontId,
				shotCustomAttrs
			);
		},
		addToBag = () => {
			const rightSystem =
				upsellBaseColour &&
				upsellShot &&
				miracleSystem.variants.find(
					(item) =>
						item.title.includes(
							parseFloat(
								upsellBaseColour.product.title.match(
									/[-+]?([0-9]*\.[0-9]+|[0-9]+)/
								)
							).toFixed(1)
						) &&
						item.title
							.toLowerCase()
							.includes(
								upsellShot.product.title
									.split('-')[0]
									.toLowerCase()
									.trim()
							)
				);

			rightSystem &&
				rightSystem.storefrontId &&
				replaceCartItem(
					cart.id,
					item,
					item.node.merchandise.id,
					item.node.id,
					rightSystem.storefrontId,
					[],
					1,
					sellingPlanId
				);
		};

	useEffect(() => {
		setDesktop(width > 1000);
	}, [width]);

	useEffect(() => {
		return () => setSelectedValue('');
	}, []);

	return (
		<div className='cart-upsell'>
			<div className='shot__container'>
				<div
					className={`shot__shade-shot shade-shot
                ${productHasShadeShot && 'shade-shot_white'}`}
				>
					<div className='shade-shot__body'>
						<div className='shade-shot__img'>
							{upsellShot && upsellShot.product.images[0].src ? (
								<LazyLoadImage
									src={upsellShot.product.images[0].src}
									alt={
										upsellShot.product.title
											? upsellShot.product.title
											: 'Shade shot'
									}
									effect='blur'
								/>
							) : (
								<LocalImage
									image='pdp/yout-shade-shot.png'
									alt='You shade shot'
								/>
							)}
						</div>
						<div className='shade-shot__content'>
							{!productHasShadeShot && (
								<div className='shade-shot__subtitle'>
									Personalise your colour with
								</div>
							)}
							<div className='shade-shot__title-bloc'>
								<h3 className='shade-shot__title'>
									{upsellShot && upsellShot.product.title
										? upsellShot.product.title
										: 'Shade Shot'}
								</h3>
								{!productHasShadeShot && (
									<button
										onClick={handleHelp}
										className='shade-shot__help'
									>
										?
									</button>
								)}
							</div>
							{((isDesktop && !productHasShadeShot) ||
								!isDesktop) && (
								<div className='shade-shot__price-bloc'>
									<div className='shade-shot__price'>£ 5</div>
									<div className='shade-shot__rating'>
										* * * * *
									</div>
								</div>
							)}
							{!productHasShadeShot && (
								<p className='shade-shot__text'>
									Customise your hair colour finish and add a
									rich mix of personalised tones
								</p>
							)}
							<div className='shade-shot__button-up'>
								{productHasShadeShot && (
									<div className='shade-shot__button_check'>
										<button
											onClick={handleChangeTone}
											className='shot-button'
										>
											<div className='shot-button__icon'>
												<LocalImage
													image='pdp/shade-btn-image.png'
													alt='Icon'
												/>
											</div>
											change tone
										</button>
									</div>
								)}
							</div>
							{productHasShadeShot && (
								<button
									onClick={handleRemoveShot}
									className='shade-shot__remove'
								>
									Remove
								</button>
							)}
						</div>
					</div>
					{!productHasShadeShot && (
						<div className='shade-shot__button'>
							<button
								onClick={handleChangeTone}
								className='shot-button'
							>
								<div className='shot-button__icon'>
									<LocalImage
										image='pdp/shade-btn-image.png'
										alt='Icon'
									/>
								</div>
								Choose tones
							</button>
						</div>
					)}
				</div>
				{!productHasShadeShot && (
					<div
						className={`shot__free-shot free-shot ${
							selectedValue && 'free-shot--selected'
						}`}
					>
						<div className='free-shot__body'>
							<div className='free-shot__img'>
								<LocalImage
									image='pdp/miracle-condition.png'
									alt='Miracle condition'
								/>
							</div>
							<div className='free-shot__content'>
								{!selectedValue && (
									<>
										<div className='free-shot__subtitle'>
											Josh recommends to boost hair health
										</div>
										<h3 className='free-shot__title'>
											Add a Miracle Shot £ 15
										</h3>
										<p className='free-shot__text'>
											Miracle Shot uses the colour to lock
											in its{' '}
											<strong>
												unique mix of vitamins{' '}
											</strong>
											and <strong>conditions</strong> from
											within, for longer lasting{' '}
											<strong>shine </strong>
											and <strong>strength</strong>
										</p>
									</>
								)}
								<button
									onClick={() => addToBag()}
									className='cart-item-bundle__button button button--dark'
								>
									add to bag
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CartUpsell;

// External
import React, { useEffect } from 'react';

// Internal
import SEO from '../../../components/SEO';

import './AllReviews.scss';

const AllReviews = ({ location, pageContext: { page } }) => {
	const appKey = process.env.YOTPO_ID;

	useEffect(() => {
		const loadYotpoScript = () => {
			const script = document.createElement('script');
			script.type = 'text/javascript';
			// script.src = `//staticw2.yotpo.com/${appKey}/widget.js`;
			script.src = `https://cdn-widgetsrepository.yotpo.com/v1/loader/${appKey}`;

			script.onload = () => {
				window.yotpo?.initWidgets && window.yotpo?.initWidgets();
			};

			document.head.appendChild(script);

			script.onload = () => {
				if (typeof window?.yotpo !== 'undefined') {
					window.yotpo?.refreshWidgets &&
						window.yotpo?.refreshWidgets();
				} else {
					loadYotpoScript();
				}
			};
		};
        
		loadYotpoScript();
	}, []);

	const headerText = 'Josh Wood Colour Customer Reviews';

	return (
		<>
			<SEO page={page} location={location} title={headerText} />
			<div className='all-reviews-page'>
				<div className='container'>
					<h1>{headerText}</h1>
					<div id='yotpo-testimonials-custom-tab' />
				</div>
			</div>
		</>
	);
};

export default AllReviews;

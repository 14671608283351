import React, { useContext, useEffect, useState } from 'react';
import { useStoryblokState } from 'gatsby-source-storyblok';
import cn from 'classnames';
import MobileMenu from './MobileMenu';
import MobileMenuSecondary from './MobileMenuSecondary';
import { useScrollBlock, useWindowHeight } from '../../../utils/hooks';
import { StoreContext } from '../../../context/store-context';
import './MobileDropdown.scss';

const MobileDropdown = ({ isDropdownOpened, setDropdownOpen }) => {
	const { mobileMenuPrimary, mobileMenuSecondary } = useContext(StoreContext);
	const menuPrimary = useStoryblokState(mobileMenuPrimary ?? '');
	const menuSecondary = useStoryblokState(mobileMenuSecondary ?? '');
	const [isSubmenuOpened, setOpenedSubmenu] = useState(false);
	const [minHeight, setMinHeight] = useState(0);
	const windowHeight = useWindowHeight();
	const [blockScroll, allowScroll] = useScrollBlock();

	useEffect(() => {
		isDropdownOpened ? blockScroll() : allowScroll();
	}, [isDropdownOpened]);

	useEffect(() => {
		if (windowHeight > minHeight) {
			setMinHeight(windowHeight);
			document.documentElement.style.setProperty(
				'--app-height',
				`${windowHeight}px`
			);
		}
	}, [windowHeight]);

	return (
		<>
			<nav className={cn('mobile-nav', { show: isDropdownOpened })}>
				<div className='mobile-nav__flex'>
					<div className='mobile-nav__header'>
						<button
							className={cn('mobile-nav__close', {
								'mobile-nav__close--right': isSubmenuOpened,
							})}
							aria-label='Mobile Nav Close'
							onClick={() => setDropdownOpen(false)}
						/>
					</div>
					<div
						className={cn('mobile-nav__content', {
							'mobile-nav__content--margin': isSubmenuOpened,
						})}
					>
						<MobileMenu
							menu={menuPrimary}
							setMenuOpen={setDropdownOpen}
							isMenuOpened={isDropdownOpened}
							isSubmenuOpened={isSubmenuOpened}
							setOpenedSubmenu={setOpenedSubmenu}
						/>
					</div>
					<MobileMenuSecondary
						menu={menuSecondary}
						setDropdownOpen={setDropdownOpen}
					/>
				</div>
			</nav>
			<div
				className={cn('overlay overlay--burger', {
					'overlay--active': isDropdownOpened,
				})}
				role='button'
				aria-label='overlay'
				onClick={() => setDropdownOpen(false)}
				onKeyDown={() => setDropdownOpen(false)}
				tabIndex='0'
			/>
		</>
	);
};

export default MobileDropdown;

import React from 'react';

import ProductAwardItemV2 from './ProductAwardItemV2';

import './ProductAwardV2.scss';

const ProductAwardsV2 = ({ awards, product }) => (
	<div className='pdp-awards-v2'>
		<div className='container'>
			<ul className='pdp-awards-v2__items'>
				{(awards || []).map((award, i) => (
					<ProductAwardItemV2
						/* eslint-disable-next-line */
						key={i}
						award={award}
						idx={i}
						product={product}
					/>
				))}
			</ul>
		</div>
	</div>
);

export default ProductAwardsV2;

import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './ProductDifference.scss';

const data = [
	{
		title: 'Josh Wood Colour',
		logo: '/images/logo-josh.svg',
		fields: Array(9).fill('checkmark'),
	},
	{
		title: 'Standard Home Kits',
		fields: [
			'cross',
			'checkmark',
			'cross',
			'cross',
			'cross',
			'cross',
			'checkmark',
			'cross',
			'checkmark',
		],
	},
	{
		title: 'Salon Treatments',
		fields: [
			'checkmark',
			'cross',
			'cross',
			'checkmark',
			'cross',
			'cross',
			'cross',
			'checkmark',
			'cross',
		],
	},
];

const fieldLabels = [
	'Highest quality ingredients & results',
	'Money saving',
	'Ammonia free',
	'Leaves hair in better condition than before',
	'Strengthens hair over time',
	'Doesn’t damage hair',
	'Easy at home application',
	'Good for scalp',
	'100% grey coverage',
];

const ProductDifference = () => (
	<div className='pdp-difference'>
		<div className='pdp-difference__content container'>
			<div className='pdp-difference__content__title'>
				<h2>The Josh Wood Colour difference</h2>
			</div>

			<div className='pdp-difference__content__table'>
				<div>
					<div className='pdp-difference__content__table-title' />
					{fieldLabels.map((label) => (
						<p
							key={label}
							className='pdp-difference__content__table-field'
						>
							{label}
						</p>
					))}
				</div>

				{data.map((item, index) => (
					<div
						key={item.title}
						className={`${
							index === 0 &&
							'pdp-difference__content__table-border-gradient'
						}`}
					>
						<div className='pdp-difference__content__table-title'>
							{item.logo ? (
								<LazyLoadImage
									src={item.logo}
									alt={item.title}
									effect='blur'
								/>
							) : (
								item.title
							)}
						</div>
						{item.fields.map((field, fieldIndex) => (
							<p
								key={`${fieldIndex + 1}`}
								className={`pdp-difference__content__table-field ${field}`}
							/>
						))}
					</div>
				))}
			</div>
		</div>
	</div>
);

export default ProductDifference;

import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './MobileDropdown.scss';

const NavLink = ({ blok, isSubItem, setMenuOpen, isTopLevel }) => {
	const {
		image,
		descriptor = '',
		title = '',
		url,
		backgroundColor,
		textColor,
	} = blok;

	return (
		<div
			className={`nav-padding ${isTopLevel ? 'mobile-menu__top-link' : ''}`}
			style={{
				backgroundColor: `#${backgroundColor}`,
			}}
		>
			<Link
				onClick={() => setMenuOpen(false)}
				className={
					isSubItem ? 'mobile-menu__sub-link' : 'mobile-menu__link'
				}
				to={url?.url ?? '/'}
			>
				{image && image.filename && (
					<LazyLoadImage
						src={image.filename}
						alt={title}
						className={
							isSubItem
								? 'mobile-menu__sub-image'
								: 'mobile-menu__image'
						}
						effect='blur'
					/>
				)}
				<div
					className={
						isSubItem
							? 'mobile-menu__sub-text-box'
							: 'mobile-menu__text-box'
					}
				>
					{descriptor !== '' && (
						<span className='mobile-menu__descriptor'>
							{descriptor}
						</span>
					)}
					<span
						className='mobile-menu__item-text'
						style={{ color: `#${textColor}` }}
					>
						{title}
					</span>
				</div>
			</Link>
		</div>
	);
};

export default NavLink;

import React from 'react';
import { storyblokEditable } from 'gatsby-source-storyblok';
import LocalVideo from '../../../LocalVideo';
import { matchImgExtension } from '../../../../utils/helpers';
import './style.scss';

const LandingHero = ({ blok }) => {
	const { title, subtitle, mediaSmall, mediaLarge, size } = blok;

	const imageTypes = ['webp', 'jpg', 'jpeg', 'png'];

	const mediaLargeIsImage = imageTypes.some((ext) =>
		matchImgExtension(ext, mediaLarge.filename)
	);
	const mediaSmallIsImage = imageTypes.some((ext) =>
		matchImgExtension(ext, mediaSmall.filename)
	);

	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className={`hero-banner hero-banner--${size}`}
		>
			<div className='hero-banner__video'>
				<div className='hero-banner__video--small'>
					{mediaSmallIsImage ? (
						<img
							src={mediaSmall.filename}
							alt={mediaSmall.alt}
							className='hero-banner__mobile--image'
						/>
					) : (
						<LocalVideo
							source={mediaSmall.filename}
							className='hero-banner__mobile'
							name='hero-video-small'
						/>
					)}
				</div>
				<div className='hero-banner__video--large'>
					{mediaLargeIsImage ? (
						<img
							src={mediaLarge.filename}
							alt={mediaLarge.alt}
							className='hero-banner__desktop--image'
						/>
					) : (
						<LocalVideo
							source={mediaLarge.filename}
							className='hero-banner__desktop'
							name='hero-video-large'
						/>
					)}
				</div>
			</div>
			<div className='hero-banner__content'>
				<h1>{title}</h1>
				<h2>{subtitle}</h2>
			</div>
		</div>
	);
};

export default LandingHero;

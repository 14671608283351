import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
	getDiscountPrice,
	getMetafield,
	getPrice,
	isAvailable,
	sendProductClickEvent,
} from '../../../utils/helpers';
import LocalImage from '../../../components/LocalImage';
import RatingStars from '../../../components/Rating/RatingStars';
import getProductRating from '../../../helpers/getProductRating';
import { StoreContext } from '../../../context/store-context';
import isAWholeNumber from '../../../helpers/isAWholeNumber';
import Button from '../../../components/Button';

const ProductItem = ({ product, sellingPlanGroup, cart }) => {
	const { subscriptionsEnabled, yotpoRatings } = useContext(StoreContext);

	const [groupFound, setGroupFound] = useState(
		sellingPlanGroup && Object.keys(sellingPlanGroup).length
	);
	const [discountPrice, setDiscountPrice] = useState(
		product.variants[0].price
	);
	const [isBundleVariant, setIsBundleVariant] = useState(
		!sellingPlanGroup && product.handle.includes('bundle')
	);
	const [isBundle, setIsBundle] = useState(false);
	const [priceValue, setPriceValue] = useState('');
	const isMiracle = getMetafield(product, 'is_miracle_system');
	const isSubscribeOnPDP = getMetafield(product, 'subscription_on_pdp');
	const [canSubscribe, setCanSubscribe] = useState(
		groupFound && subscriptionsEnabled && isSubscribeOnPDP
	);
	const isCustomizeAndPDP = getMetafield(product, 'is_customize_and_pdp');

	const productRating = getProductRating(product, yotpoRatings);
	const reviewsAverage = productRating?.product_score;
	const reviewsCount = productRating?.total_reviews;

	function getPriceProduct() {
		if (isBundleVariant) {
			return product;
		}

		return isMiracle
			? product.variants[0]
			: product.variants.filter(
					(variant) =>
						!variant.title.toLowerCase().includes('miracle')
			  )[0];
	}

	const [price, setPrice] = useState(getPriceProduct());

	useEffect(() => {
		if (sellingPlanGroup) {
			setGroupFound(Object.keys(sellingPlanGroup).length);
		}
	}, [sellingPlanGroup]);

	useEffect(() => {
		setCanSubscribe(groupFound && subscriptionsEnabled && isSubscribeOnPDP);
	}, [groupFound]);

	useEffect(() => {
		(product.options || []).forEach((option) => {
			const name = option.name.toLowerCase();
			if (name === 'bundle') setIsBundle(true);
		});
	}, []);

	useEffect(() => {
		if (canSubscribe) {
			const plan = sellingPlanGroup.sellingPlans.edges[0],
				productPrice = parseInt(product.variants[0].price),
				discount = getDiscountPrice(
					productPrice,
					plan.node.pricingPolicies[0].adjustmentValue.percentage
				);
			setDiscountPrice(discount);
		}
	}, [canSubscribe]);

	useEffect(() => {
		setPrice(getPriceProduct());
	}, [isBundle, isMiracle]);

	useEffect(() => {
		const priceIsWholeNumber = isAWholeNumber(price.price);
		setPriceValue(
			isSubscribeOnPDP
				? `£${
						isAWholeNumber(discountPrice)
							? parseInt(discountPrice)
							: discountPrice
				  }`
				: `£${priceIsWholeNumber ? parseInt(price.price) : price.price}`
		);
	}, [discountPrice, price, product]);

	const adjustPrice = () => {
		const priceOfProduct = price[canSubscribe ? 'price' : 'compareAtPrice'];
		const priceIsWholeNumber = isAWholeNumber(priceOfProduct);

		return priceIsWholeNumber ? 0 : 2;
	};

	const shouldDisplayTextButton =
		isMiracle || isCustomizeAndPDP || canSubscribe;

	return (
		<div className='pdp-collections__content__product'>
			<Link
				to={`/products/${product.handle}`}
				onClick={() => sendProductClickEvent(product)}
			>
				<div className='product-list-item__img'>
					{product.media[0] ? (
						<>
							{product.media[0].preview.image.gatsbyImageData ? (
								<GatsbyImage
									alt={product.title}
									image={
										product.media[0].preview.image
											.gatsbyImageData
									}
								/>
							) : (
								<LazyLoadImage
									src={
										product.media[0].preview.image
											.originalSrc
									}
									alt={product.title}
									effect='blur'
								/>
							)}
						</>
					) : (
						<LocalImage image='no-image.jpg' alt={product.title} />
					)}
				</div>
			</Link>
			<div className='product-list-item__info'>
				<div className='product-list-item__rating'>
					{reviewsAverage && reviewsCount ? (
						<RatingStars
							rating={reviewsAverage}
							count={reviewsCount}
						/>
					) : (
						<p>No reviews yet</p>
					)}
				</div>
				<Link
					to={`/products/${product.handle}`}
					onClick={() => sendProductClickEvent(product)}
				>
					<h3>{product.title}</h3>
				</Link>
			</div>
			<div className='pdp-collections__content__product__price'>
				{product.variants && parseInt(price.price) ? (
					<>
						<p className='bold'>
							{(!isMiracle || (isMiracle && !canSubscribe)) &&
								!canSubscribe &&
								priceValue}
						</p>
						{price.compareAtPrice || canSubscribe ? (
							<div
								className={`${
									!isMiracle &&
									!canSubscribe &&
									'pdp-collections__content__product__price--discount'
								}`}
							>
								{getPrice(
									price[
										canSubscribe
											? 'price'
											: 'compareAtPrice'
									],
									cart,
									adjustPrice()
								)}
								{canSubscribe ? (
									<span className=''>
										{' '}
										single purchase or <br />
										<strong>{priceValue}</strong> Subscribe
										& Save
									</span>
								) : null}
							</div>
						) : null}
					</>
				) : null}
			</div>
			<Button
				buttonText={
					shouldDisplayTextButton ? 'Customise' : 'Add to bag'
				}
				product={product}
				className='pdp-collections__content__product__button'
				isLink={shouldDisplayTextButton}
				linkUrl={
					shouldDisplayTextButton && `/products/${product.handle}`
				}
				isOutlinePink
				isBundle={isBundle}
				availableForSale={isAvailable(product)}
			/>
		</div>
	);
};

export default ProductItem;

import React from 'react';

import LocalImage from '../../../components/LocalImage';

import './TiredOfHair.scss';

const TiredOfHair = () => (
	<div className='tired_hair'>
		<div className='tired_hair__background'>
			<div className='tired_hair__background__top' />
			<div className='tired_hair__background__bottom' />
		</div>
		<div className='tired_hair__body'>
			<h2 className='tired_hair__body__title'>
				Tired of hair colour damage?
			</h2>
			<h3 className='tired_hair__body__subtitle'>
				The Miracle System strengthens your hair with every colour
				application
			</h3>
			<div className='tired_hair__body__desc'>
				<div className='tired_hair__body__desc__miracle'>
					<LocalImage image='miracle-shot-2.png' alt='Miracle Shot' />
					<p className='tired_hair__body__desc__miracle-container'>
						<strong>Miracle Shot</strong> infuses vitamins deep into
						your hair, boosting shine, strength, and condition from
						within.
					</p>
				</div>
				<div className='tired_hair__body__desc__img'>
					<svg
						width='174'
						height='250'
						viewBox='0 0 174 250'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M67.402 167.828C76.2731 136.178 80.2894 117.29 100.304 88.7469C112.181 71.8091 121.29 63.315 136.757 48.086C148.711 36.3161 158.451 31.4088 169.186 19.0055C191.722 -7.03096 115.884 48.8757 90.5971 72.85C65.1223 97.0025 48.7942 111.8 32.9939 139.556C17.4774 166.813 14.5233 183.572 8.80491 212.857C2.9706 242.734 3.04486 286.396 3.93458 290.441C4.82429 294.486 5.96218 302.921 28.472 304.788C50.9818 306.656 56.3869 293.205 56.7469 288.866C57.1069 284.527 58.531 199.477 67.402 167.828Z'
							fill='#545454'
						/>
						<path
							d='M123.847 74.9L111.9 75.0468L112.047 86.9936'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M123.847 74.9L111.9 75.0468L112.047 86.9936'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M111.9 75.0469L133.668 96.2867'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M111.9 75.0469L133.668 96.2867'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M139.847 57.9L127.9 58.0468L128.047 69.9936'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M139.847 57.9L127.9 58.0468L128.047 69.9936'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M127.9 58.0466L149.668 79.2865'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M127.9 58.0466L149.668 79.2865'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M156.847 41.9L144.9 42.0468L145.047 53.9936'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M156.847 41.9L144.9 42.0468L145.047 53.9936'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M144.9 42.0466L166.668 63.2865'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M144.9 42.0466L166.668 63.2865'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M22.9658 213.035L31.4141 204.586L22.9658 196.138'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M22.9658 213.035L31.4141 204.586L22.9658 196.138'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M31.4141 204.586H17.8968C8.5648 204.586 1.00019 197.022 1.00019 187.69V186'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M31.4141 204.586H17.8968C8.5648 204.586 1.00019 197.022 1.00019 187.69V186'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M30.6577 180.511L39.106 172.063L30.6577 163.615'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M30.6577 180.511L39.106 172.063L30.6577 163.615'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M39.106 172.063H25.5887C16.2567 172.063 8.69208 164.498 8.69208 155.166V153.477'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M39.106 172.063H25.5887C16.2567 172.063 8.69208 164.498 8.69208 155.166V153.477'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M44.9477 148.034L53.396 139.586L44.9477 131.138'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M44.9477 148.034L53.396 139.586L44.9477 131.138'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M53.396 139.586H39.8787C30.5467 139.586 22.9821 132.022 22.9821 122.69V121'
							stroke='#EBF9FF'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M53.396 139.586H39.8787C30.5467 139.586 22.9821 132.022 22.9821 122.69V121'
							stroke='black'
							strokeOpacity='0.2'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</div>
				<div className='tired_hair__body__desc__miracle'>
					<LocalImage image='shade-shot-2.png' alt='Shade Shot' />
					<p className='tired_hair__body__desc__miracle-container'>
						<strong>Shade Shot</strong> creates a personalised hair
						colour tone to complement your skin and eyes for a look
						that's uniquely you.
					</p>
				</div>
			</div>
		</div>
	</div>
);

export default TiredOfHair;
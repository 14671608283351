// External
import React, { useState } from 'react';
import { Link } from 'gatsby';

// Internal
import LocalImage from '../../../components/LocalImage';
import Breadcrumb from '../../../components/BreadCrumb/Index';

import { PRODUCT_SECTION_BREADCUMB } from '../../../constants/breadcrumbs';

import './ProductListInfo.scss';

const ProductListInfo = ({ title, descriptionHtml, handle }) => {
	const [isMoreOpened, setMoreOpened] = useState(false);

	return (
		<div className='product-list-info plp-page__info'>
			<div className='product-list-info__container'>
				<Breadcrumb section={PRODUCT_SECTION_BREADCUMB} />
				<h1 className='product-list-info__title'>{title}</h1>
				<div className='product-list-info__flex'>
					<div
						className={`product-list-info__text ${
							isMoreOpened && 'product-list-info__text--active'
						}`}
						dangerouslySetInnerHTML={{
							__html: descriptionHtml,
						}}
					/>
					{handle.includes('gloss') && (
						<Link
							to='/blogs/community/the-glaze-craze'
							className='classic-link classic-link--margin'
						>
							Find out more about the Gloss and Glaze Collection
						</Link>
					)}
				</div>
				<button
					onClick={() => setMoreOpened(!isMoreOpened)}
					className='product-list-info__read-more'
				>
					{!isMoreOpened ? 'read more' : 'read less'}
				</button>
			</div>
			{handle !== 'colour' &&
				handle !== 'gloss-and-glaze' &&
				handle !== 'all-products' &&
				handle !== 'root-touch-up' &&
				handle !== 'gifts' &&
				handle !== 'care' &&
				handle !== 'accessories' && (
					<div className='product-list-info__img'>
						<LocalImage
							alt={title}
							image={`collections/${handle}.jpg`}
						/>
					</div>
				)}
		</div>
	);
};

export default ProductListInfo;

import littledata from '@littledata/headless-shopify-sdk';
import Cookies from 'js-cookie';
import { submitErrorToSlack } from '../utils/slackMessage';

const getLittledataCartAttributes = async () => {
	const fetchClientTracker = littledata.fetchClientIds({
		ga4MeasurementId: `G-5TT6JG7CTF`,
	});

	const trackerError = new Error('User is blocking the tracker');

	let result = [];
	try {
		const timeoutPromise = new Promise((_resolve, reject) => {
			setTimeout(() => reject(trackerError), 6000);
		});
		result = await Promise.race([fetchClientTracker, timeoutPromise]);
	} catch (error) {
		const errorMessage = `Error fetching littledata client tracker: ${error}`;
		console.error(errorMessage);
		submitErrorToSlack('Littledata Client', errorMessage, 'POST');
	}

	const noteAttributes = result || [];
	// TODO: Elevar is not used anymore, so we can remove this.

	const attributionData =
		localStorage.getItem('attributionData') || 'undefined';
	const sanitizedAttributionData = attributionData.replace(/"/g, '\\"');

	const ga4id = '5TT6JG7CTF';
	const fbp = Cookies.get('_fbp') || 'undefined';
	const ga = Cookies.get('_ga') || 'undefined';
	const ga4 = Cookies.get(`_ga_${ga4id}`) || 'undefined';

	const attributes = [
		...noteAttributes,
		{
			key: '_elevar_visitor_info',
			value: sanitizedAttributionData,
		},
		{
			key: '_elevar__fbp',
			value: fbp,
		},
		{
			key: '_elevar__ga',
			value: ga,
		},
		{
			key: `_elevar__ga_${ga4id}`,
			value: ga4,
		},
	];
	return attributes;
};

export default getLittledataCartAttributes;

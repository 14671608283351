import React from 'react';
import ProductListInfo from '../collections/ProductListInfo';
import Filter from '../../components/Filter';
import ProductsListGridV2 from './ProductsListGridV2';
import CollectionGrid from '../../components/CollectionGrid';
import EmptyCollection from '../../components/EmptyCollection';
import hideFiltersCollection from '../../constants/hide-filters-collection';

const ProductsListPage = ({
	productsList,
	cart,
	collection,
	filter,
	collectionFilters,
	collectionSorts,
	allSellingPlanGroups,
}) => {
	const hideFilters = hideFiltersCollection.includes(collection.handle);
	return (
		<div className='plp-page'>
			<div className='container'>
				<ProductListInfo {...collection} />
				{!productsList.length ? (
					<EmptyCollection />
				) : (
					<>
						{!hideFilters && (
							<Filter
								query={filter}
								list={productsList}
								collectionFilters={collectionFilters}
								collectionSorts={collectionSorts}
							/>
						)}
						<ProductsListGridV2
							collection={collection}
							productsList={productsList}
							cart={cart}
							filter={filter}
							allSellingPlanGroups={allSellingPlanGroups}
						/>
						<CollectionGrid collectionPageV2 />
					</>
				)}
			</div>
		</div>
	);
};

export default ProductsListPage;

import React, { useContext, useState, useEffect } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import LocalImageOptimized from '../../LocalImage/LocalImageOptimized';
import { useWindowWidth } from '../../../utils/hooks';
import { StoreContext } from '../../../context/store-context';
import ProductListItemContainer from '../../ProductListItem/ProductListItemContainer';

import './style.scss';

SwiperCore.use([Navigation]);

const FiltersProducts = ({ blok, cart, allSellingPlanGroups }) => {
	const { Title, banner, filters } = blok;
	const width = useWindowWidth();
	const { allProducts, allCollections } = useContext(StoreContext);
	const [handleSelected, setHandleSelected] = useState(filters[0].Handle);
	const [isLoading, setIsLoading] = useState(true);

	const productsToShow = allProducts?.filter((product) =>
		product?.collections.some(
			(collection) => collection.handle === handleSelected
		)
	);

	const collectionSelected = allCollections?.find(
		(collection) => collection.handle === handleSelected
	);

	const isMobile = width < 750;

	useEffect(() => {
		if (productsToShow) {
			setIsLoading(false);
		}
	}, [handleSelected]);

	return (
		<>
			<div className='carousel-container'>
				<h2 className='carousel-title'>Christmas Shop</h2>
				<LocalImageOptimized
					imageUrl={banner.filename}
					alt={`Background banner ${Title}`}
					customClass='carousel-background-image'
				/>
				<Swiper
					spaceBetween={5}
					slidesPerView={isMobile ? 2 : 6}
					navigation={{
						prevEl: '.swiper-button-prev-ms',
						nextEl: '.swiper-button-next-ms',
					}}
					className='swiper-container'
					allowTouchMove={false}
				>
					{filters.map((filter, index) => (
						<SwiperSlide
							className='swiper-slide'
							key={`${filter.Text}`}
						>
							<div
								className='carousel-item'
								onClick={() => setHandleSelected(filter.Handle)}
							>
								<LocalImageOptimized
									alt={filter.Text}
									imageUrl={filter.Image.filename}
									customClass='carousel-item__image'
								/>
								<div
									style={{
										height: '50px',
									}}
								>
									<span className='carousel-item__text'>
										{filter.Text}
									</span>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>

				<div id='custom-prev' className='swiper-button-prev-ms' />
				<div id='custom-next' className='swiper-button-next-ms' />
			</div>

			<div className='products-list-grid-container'>
				{productsToShow && productsToShow.length ? (
					<ul className='products-list-grid'>
						{productsToShow?.map((item, index) => (
							<ProductListItemContainer
								index={index + 1}
								key={item.id}
								product={item}
								cart={cart}
								allSellingPlanGroups={allSellingPlanGroups}
								hideRating
								collection={collectionSelected}
							/>
						))}
					</ul>
				) : (
					<div className='products-list__empty'>
						Please check back soon
					</div>
				)}
			</div>
		</>
	);
};

export default FiltersProducts;

import React from 'react';
import { Link } from 'gatsby';

const FooterBottom = () => (
	<div className='footer__bottom'>
		<div className='footer__bottom-item'>
			© 2021,{' '}
			<Link to='/' title=''>
				Josh Wood Colour
			</Link>
		</div>
		<div className='footer__bottom-item'>
			<Link to='/pages/cookie-policy' title=''>
				Cookie Policy
			</Link>
		</div>
	</div>
);

export default FooterBottom;

import React, { useContext, useEffect, useState, useRef } from 'react';
import { StoreContext } from '../../../context/store-context';
import {
	getSellingPlanGroupDefaultValue,
	getSellingPlanGroupOptions,
	isBrowser,
} from '../../../utils/helpers';
import ItemGallery from './ItemGallery';
import ConfirmButton from './ConfirmButton';
import CurrentChoice from './CurrentChoice';
import Colour from './Colour';
import Tone from './Tone';
import UpsellContent from './UpsellContent';
import './ModalPermanentMobile.scss';

const ModalPermanentMobile = ({ modal }) => {
	const {
		allBaseColours,
		miracleSystem,
		product,
		shadeShotsProducts,
		withShadeShot,
		sellingPlanGroup,
	} = modal.info;

	const { showModal, setCart, addVariantToCart, cart, subscriptionsEnabled } =
		useContext(StoreContext);

	const [groupFound, setGroupFound] = useState(
		Object.keys(sellingPlanGroup).length
	);
	const [canSubscribe, setCanSubscribe] = useState(
		groupFound && subscriptionsEnabled
	);
	const [options, setOptions] = useState(
		canSubscribe
			? getSellingPlanGroupOptions(sellingPlanGroup.sellingPlans.edges)
			: []
	);
	const [selectedValue, setSelectedValue] = useState(
		canSubscribe ? getSellingPlanGroupDefaultValue(options) : null
	);
	const [permanentColour, setPermanentColour] = useState(null);
	const [step, setStep] = useState(1);
	const [buttonText, setButtonText] = useState('');
	const [baseColours, setBaseColours] = useState([]);
	const [shadeShots, setShadeShots] = useState([]);
	const [selectedBase, setBaseColour] = useState('');
	const [selectedTone, setSelectedTone] = useState('');
	const [showUpsell, setShowUpsell] = useState(false);
	const [currentShadeShot, setCurrentShadeShot] = useState({});
	const [productWithShots, setProductWithShots] = useState({});
	const [isCheckShade, setCheckShade] = useState(false);
	const [isCheckFree, setCheckFree] = useState(false);
	const [isThankYou, setThankYou] = useState(false);

	const previousUrl = useRef(window.location.pathname);

	const currentUrl = window.location.pathname;

	const [currentMiracleSystem, setCurrentMiracleSystem] = useState();
	const productContainsShadeShot = product.title.includes('Shade Shot');
	const handleClose = () => {
		modal && showModal(false);
		setCheckShade(false);
		setCheckFree(false);
		setShowUpsell(false);
		setStep(1);
		setThankYou(false);
		setCurrentMiracleSystem();
	};
	const goStep = (number) => {
		if (number === 1) {
			setCheckShade(false);
		}
		setStep(number);
		setShowUpsell(false);
	};
	const handleSubmit = async () => {
		if (step === 1 && !showUpsell) {
			setShowUpsell(true);
			goStep(2);
			return;
		}

		if (step === 2 && !showUpsell) {
			setCheckShade(true);
			setShowUpsell(true);
			return;
		}

		if (step === 3) {
			setShowUpsell(false);
			handleClose();
			return;
		}

		const cartId = isBrowser && localStorage.getItem('shopify_cart_id');

		if (currentMiracleSystem) {
			await addVariantToCart(currentMiracleSystem.storefrontId, 1).then(
				(data) => { }
			);
			return;
		}

		if (step === 1 && showUpsell) {
			const { storefrontId } = permanentColour.variants[0];
			await addVariantToCart(storefrontId, 1).then((data) => {
				setCart(data.cartLinesAdd.cart);
			});
		}

		if (step === 2 && showUpsell && !isCheckFree) {
			const { storefrontId } = shadeShots.filter(
				(item) =>
					item.title.toLowerCase().replace(' ', '-') ===
					selectedTone ||
					item.title.toLowerCase().replace(' ', '-') ===
					'default-title'
			)[0];
			goStep(3);
			await addVariantToCart(storefrontId, 1).then((data) => { });
			return;
		}

		if (step === 2 && isCheckFree) {
			const { storefrontId } = miracleSystem.variants.find(
				(item) =>
					item.title.includes(selectedBase.replace('-', '.')) &&
					item.title
						.toLowerCase()
						.includes(selectedTone.split('-')[0])
			),
				sellingPlanId = canSubscribe
					? sellingPlanGroup.sellingPlans.edges[selectedValue].node.id
					: null;

			await addVariantToCart(
				storefrontId,
				1,
				[],
				false,
				null,
				sellingPlanId
			).then((data) => {
				setCart(data.cartLinesAdd.cart);
			});
		}
	};

	const handleSubmitWithoutTone = async () => {
		if (step === 1 && !showUpsell) {
			setShowUpsell(true);
			return;
		}

		if (step === 3) {
			setShowUpsell(false);
			handleClose();
			return;
		}

		if (step === 1 && showUpsell) {
			const { storefrontId } = permanentColour.variants[0];
			goStep(3);
			await addVariantToCart(storefrontId, 1).then((data) => {
				setCart(data.cartLinesAdd.cart);
			});
		}

		if (step === 2 && !isCheckFree) {
			const { storefrontId } = shadeShots.filter(
				(item) =>
					item.title.toLowerCase().replace(' ', '-') ===
					selectedTone ||
					item.title.toLowerCase().replace(' ', '-') ===
					'default-title'
			)[0];
			goStep(3);
			await addVariantToCart(storefrontId, 1).then((data) => { });
			return;
		}

		if (step === 2 && !showUpsell && isCheckFree) {
			const { storefrontId } = miracleSystem.variants.find(
				(item) =>
					item.title.includes(selectedBase.replace('-', '.')) &&
					item.title
						.toLowerCase()
						.includes(selectedTone.split('-')[0])
			),
				sellingPlanId = canSubscribe
					? sellingPlanGroup.sellingPlans.edges[selectedValue].node.id
					: null;

			await addVariantToCart(
				storefrontId,
				1,
				[],
				false,
				null,
				sellingPlanId
			).then((data) => {
				setCart(data.cartLinesAdd.cart);
			});
		}
	};
	useEffect(() => {
		if (allBaseColours.length) {
			const copy = [...allBaseColours];
			/* eslint-disable-next-line no-confusing-arrow */
			copy.sort((first, second) =>
				parseFloat(first.title.split(' ')[2]) <
					parseFloat(second.title.split(' ')[2])
					? -1
					: 1
			);

			if (productContainsShadeShot) {
				const baseColoursHasShade = copy.filter((item) => {
					const hasProductShade = shadeShotsProducts.some((shade) => {
						const isTitleInclude = shade.title.includes(item.title)
						return isTitleInclude;
					});
					return hasProductShade;
				});
				setBaseColours(baseColoursHasShade);
			} else {
				setBaseColours(copy);
			}
		}
	}, [productContainsShadeShot]);

	useEffect(() => {
		const text = showUpsell
			? 'Add to bag'
			: step === 1
				? 'Confirm base colour'
				: 'Confirm tone';
		setButtonText(text);

		if (step === 3) {
			setShowUpsell(false);
			handleClose();
		}
	}, [step, showUpsell]);

	useEffect(() => {
		const numberBase = product.title.match(/\d+(\.\d+)?/);
		setBaseColour(numberBase[0].replace(".", "-"));
	}, []);

	useEffect(() => {
		if (selectedBase) {
			const productWithShots = withShadeShot.find((item) =>
				item.title.includes(selectedBase.replace('-', '.'))
			);
			setProductWithShots(productWithShots);
			const productBase = allBaseColours.find((item) =>
				item.title.includes(selectedBase.replace('-', '.'))
			);
			setPermanentColour(productBase);
			if (productWithShots && Array.isArray(productWithShots.variants)) {
				const shotsInVariants = productWithShots.variants.filter(
					(item) => !item.title.toLowerCase().includes('shot')
				);
				let selectedTone = '';
				if (
					shotsInVariants.length === 1 &&
					shotsInVariants[0].title === 'Default Title'
				) {
					selectedTone = 'black-onyx';
				} else {
					selectedTone =
						shotsInVariants[0] &&
						shotsInVariants[0].title
							.toLowerCase()
							.replace(' ', '-');
				}
				setSelectedTone(selectedTone);
				setShadeShots(shotsInVariants);
			} else {
				setShadeShots([]);
				setSelectedTone('');
			}
		}
	}, [selectedBase]);

	useEffect(() => {
		const currentShadeShot = shadeShotsProducts.filter((item) =>
			item.title
				.toLowerCase()
				.replace(' ', '-')
				.includes(
					selectedTone === 'icy-blonde'
						? 'icy'
						: selectedTone === 'champagne-blonde'
							? 'champagne'
							: selectedTone === 'default-title'
								? 'black-onyx'
								: selectedTone
				)
		)[0];
		setCurrentShadeShot(currentShadeShot);
	}, [selectedTone]);

	useEffect(() => {
		if (previousUrl.current !== currentUrl) handleClose();
	}, [currentUrl]);

	return (
		<div className='modal-permanent-mobile'>
			{showUpsell ? (
				<UpsellContent
					{...{
						permanentColour,
						productWithShots,
						currentShadeShot,
						setShowUpsell,
						setStep,
						isCheckShade,
						isCheckFree,
						setCheckFree,
						setCheckShade,
						selectedValue,
						options,
						handleClose,
						cart,
						currentMiracleSystem,
						setCurrentMiracleSystem,
						miracleSystem,
						setSelectedValue,
					}}
				/>
			) : (
				<div className='animate'>
					<CurrentChoice product={product} />
					<ItemGallery
						step={step}
						selectedBase={selectedBase}
						handleClose={handleClose}
						selectedTone={selectedTone}
					/>
					{step === 1 && (
						<Colour
							{...{
								selectedBase,
								setBaseColour,
								baseColours: baseColours
									.filter(
										(item) => !item.title.includes('8.5')
									)
							}}
						/>
					)}
					{step === 2 && (
						<Tone
							{...{
								selectedBase,
								selectedTone,
								setSelectedTone,
								shadeShots,
							}}
						/>
					)}
				</div>
			)}
			<ConfirmButton
				buttonText={buttonText}
				showUpsell={showUpsell}
				handleSubmit={
					productContainsShadeShot
						? handleSubmit
						: handleSubmitWithoutTone
				}
			/>
		</div>
	);
};

export default ModalPermanentMobile;
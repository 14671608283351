import React, { Fragment } from 'react';
import LocalImage from '../../../components/LocalImage';

import './ProductIncludeWith.scss';

const products = [
	{
		name: 'Kind Permanent Colour',
		description:
			'100% grey coverage, no ammonia, and rich, natural-looking colour.',
		image: 'miracle-about/kind-permanent-colour.png',
	},
	{
		name: 'Toning Shade Shot',
		description:
			'Glossy toner that creates personalised results to suit your skin and eyes.',
		image: 'miracle-about/toning-shade-shot.png',
	},
	{
		name: 'Repairing Miracle Shot',
		description: 'The secret sauce to softness, shine & strength.',
		image: 'miracle-about/repairing-miracle-shot.png',
	},
];

const ProductIncludeWith = ({ product }) => (
	<div className='container'>
		<div className='pdp-include'>
			<h2 className='pdp-include__title'>
				Included with {product?.title}
			</h2>

			<p className='pdp-include__desc'>
				Your 3-step hair colour solution
			</p>

			<div className='pdp-include__content'>
				{products.map((product, index) => (
					<Fragment key={product.name}>
						<div className='pdp-include__content__text'>
							<LocalImage
								image={product.image}
								alt={product.name}
							/>
							<h3>{product.name}</h3>
							<p>{product.description}</p>
						</div>

						{index < products.length - 1 && (
							<div className='pdp-include__content__divider' />
						)}
					</Fragment>
				))}
			</div>
		</div>
	</div>
);

export default ProductIncludeWith;

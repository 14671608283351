import React, { useEffect } from 'react';
import { Link } from 'gatsby';

import ButtonLink from './ButtonLink';
import ButtonMain from './ButtonMain';
import ButtonOutStock from './ButtonOutStock';

import { isBrowser } from '../../utils/helpers';

import './Button.scss';

const OutStockData = ({ isPdp, product, isLink, isPlp = false, plpVersion = "1" }) => (
	<>
		{isPdp ? (
			<ButtonOutStock product={product} isPlp={isPlp} plpVersion={plpVersion} />
		) : (
			<>{!isLink && <div>Out of stock</div>}</>
		)}
	</>
);

export default OutStockData;

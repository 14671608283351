import React, { useState } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useWindowWidth } from '../../utils/hooks';

import './TextSlider.scss';

const news = [
	'100% Money Back Guarantee',
	'Subscribe & Save 15%',
	'Free Shipping & Free Gifts!',
];

SwiperCore.use([Navigation]);

const TextSlider = () => {
	const windowWidth = useWindowWidth();

	const [swiper, setSwiper] = useState(null);

	const goNext = () => {
		if (swiper !== null) {
			swiper.slideNext();
		}
	};

	const goPrev = () => {
		if (swiper !== null) {
			swiper.slidePrev();
		}
	};

	const isMobile = windowWidth < 750;
	return (
		<div className='text-slider'>
			<div className='container text-slider__container'>
				<Swiper
					onSwiper={(swiper) => setSwiper(swiper)}
					slidesPerView={isMobile ? 1 : 3}
					initialSlide={0}
					allowTouchMove={isMobile}
					centeredSlides
					observer
					loop
					observeParents
					autoplay={false}
					navigation={{
						prevEl: '.swiper-button-prev-ms',
						nextEl: '.swiper-button-next-ms',
					}}
				>
					{news.map((item, index) => (
						<SwiperSlide key={item}>
							<span
								className='text-slider__slide'
								id={`slider-${index}`}
							>
								{item}
							</span>
						</SwiperSlide>
					))}
				</Swiper>
			</div>

			{isMobile && (
				<>
					<div
						id='custom-prev'
						className='swiper-button-prev-ms'
						onClick={goPrev}
					/>

					<div
						id='custom-next'
						className='swiper-button-next-ms'
						onClick={goNext}
					/>
				</>
			)}
		</div>
	);
};

export default TextSlider;

// External
import React from 'react';
import StarRating from 'react-svg-star-rating';

// Internal
import './RatingStars.scss';

const RatingStars = ({ rating, count, simplifiedRating = false }) => (
	<div className='rating-stars'>
		<div
			className={`stars ${simplifiedRating && 'rating-stars-simplified'}`}
		>
			<StarRating
				starClassName='rating-star'
				containerClassName='stars-container'
				initialRating={rating}
				roundedCorner='false'
				activeColor='#000'
				emptyColor='#fff'
				isReadOnly='true'
				unit='float'
				count={simplifiedRating ? 1 : 5}
			/>
		</div>
		<div className='rating-text'>
			{simplifiedRating ? (
				<p>
					{rating?.toFixed(1) ?? 0} ({count ?? 0} reviews)
				</p>
			) : (
				<p>{count}</p>
			)}
		</div>
	</div>
);

export default RatingStars;
